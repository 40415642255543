






import { Vue, Component, Emit } from "vue-property-decorator";
import Flickity from "vue-flickity/src/flickity.vue";

@Component({
  components: {
    Flickity
  }
})
export default class Reviews extends Vue {
  private flickityOptions: any = {
    prevNextButtons: false,
    wrapAround: true,
    autoPlay: 5000
  };
}
