













import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class ImageCheckout extends Vue {
  @Prop() private images: any[];
  @Prop() private imageId: string;
  private index: number = 0;

  private get image() {
    const image = this.images.find((image, index) => {
      if (image.fID == this.imageId) {
        this.index = index;
        return true;
      }
    });

    return image;
  }

  private goNext() {
    this.$router.push(`/image/${this.prevNext.next.fID}`);
  }

  private goPrev() {
    this.$router.push(`/image/${this.prevNext.previous.fID}`);
  }

  private goStart() {
    this.$router.push(`/image/${this.images[0].id}`);
  }

  private goEnd() {
    this.$router.push(`/image/${this.images[this.imageCount - 1].id}`);
  }

  private get imageCount() {
    return this.images.length;
  }

  private get prevNext() {
    return {
      previous: this.images[
        (this.index + this.imageCount - 1) % this.imageCount
      ],
      next: this.images[(this.index + 1) % this.imageCount],
    };
  }
}
