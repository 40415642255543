var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid-staggered grid-staggered--alt-2" }, [
    _c(
      "div",
      { staticClass: "grid-staggered__items", class: _vm.remainder },
      _vm._l(_vm.images, function(image) {
        return _c(
          "div",
          {
            key: image.fID,
            staticClass: "grid-staggered__item gallery-thumbnail js-lazyload",
            attrs: { "data-image": image.thumbnail, alt: "" },
            on: {
              click: function($event) {
                _vm.handleClick(image.fID)
              }
            }
          },
          [_c("i", { staticClass: "fa fa-search" })]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }