var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wysiwyg" }, [
    this.image
      ? _c("div", [
          _c("h1", [_vm._v(_vm._s(_vm.image.name))]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { domProps: { innerHTML: _vm._s(_vm.image.wysiwygDesc) } }),
          _vm._v(" "),
          _c("button", { staticClass: "cta", on: { click: _vm.goPrev } }, [
            _vm._v("previous picture")
          ]),
          _vm._v(" "),
          _c("button", { staticClass: "cta", on: { click: _vm.goNext } }, [
            _vm._v("next picture")
          ]),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "cta",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  _vm.$router.push("/")
                }
              }
            },
            [_vm._v("Go Back")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }