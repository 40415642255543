













import { Vue, Component, Prop } from "vue-property-decorator";
import { lazyLoader } from "../modules/lazyloader";
import LoadingIcon from "./loading-icon.vue";
@Component({
  components: {
    LoadingIcon,
  },
  beforeRouteUpdate(to, from, next) {
    const el = this.$refs.cimg;
    el.classList.remove("js-lazyload--visible");
    const img = el.getElementsByClassName("js-lazyload__image")[0];

    if (img) {
      img.remove();
    }
    next();
  },
})
export default class ImageSingle extends Vue {
  @Prop() private images: any[];
  @Prop() private imageId: string;
  private loading: boolean = true;
  private key: number = 0;

  private mounted() {
    lazyLoader();
  }

  private updated() {
    this.loading = true;

    lazyLoader();
  }

  private handleLoad() {
    this.loading = false;
  }

  private get image() {
    const image = this.images.find((image) => image.fID == this.imageId);

    return image;
  }

  private get aspectPercentage(): string {
    if (!(this.image && this.image.width && this.image.height)) {
      return 56.25 + "%";
    }

    return (100 * this.image.height) / this.image.width + "%";
  }
}
