var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "transition-fade" } }, [
    _c(
      "div",
      {
        staticClass: "search",
        attrs: { tabindex: "0" },
        on: {
          keyup: function($event) {
            if ($event.target !== $event.currentTarget) {
              return null
            }
            return _vm.handleKeyup($event)
          }
        }
      },
      [
        _c("i", {
          staticClass: "search__close fa fa-times",
          on: { click: _vm.closeSearch }
        }),
        _vm._v(" "),
        _c(
          "form",
          { attrs: { action: "/search-results", id: "search-form" } },
          [
            _c(
              "div",
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchValue,
                      expression: "searchValue"
                    }
                  ],
                  ref: "search",
                  staticClass: "search__input",
                  attrs: {
                    type: "text",
                    name: "query",
                    autofocus: "",
                    autocomplete: "off"
                  },
                  domProps: { value: _vm.searchValue },
                  on: {
                    keyup: _vm.handleKeyup,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchValue = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "transition-slide__left" } },
                  [
                    !_vm.searchValue
                      ? _c("p", { staticClass: "search__tooltip" }, [
                          _vm._v("Type something!")
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "transition-slide__right" } },
                  [
                    _vm.searchValue
                      ? _c(
                          "button",
                          {
                            staticClass: "search__btn",
                            attrs: { type: "submit", form: "search-form" }
                          },
                          [
                            _vm._v("\n            Let's Go! "),
                            _c("i", { staticClass: "fa fa-arrow-right" })
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }