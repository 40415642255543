import "./modules/lazyloader";
import Vue from "vue";
import VueRouter from "vue-router";
import Search from "./components/search.vue";
import Offcanvas from "./components/offcanvas.vue";
import Reviews from "./components/reviews.vue";
import ImageGallery from "./components/image-gallery.vue";
import SidebarLayout from "./layouts/sidebar.vue";
import ImageCheckout from "./components/image-checkout.vue";
import AlbumCheckout from "./components/album-checkout.vue";
import ImageSingle from "./components/image-single.vue";
import GalleryRoot from "./views/gallery-root.vue";
import VueSnotify from "vue-snotify";
import HomeVideo from "./components/home-video.vue";
import VueYoutube from "vue-youtube";

Vue.use(VueYoutube);

const componentList = {
  Search,
  Offcanvas,
  Reviews,
  ImageGallery,
  SidebarLayout,
  GalleryRoot,
  HomeVideo,
};

window.addEventListener("load", () => {
  const routes = [
    {
      path: "/",
      name: "gallery",
      components: { main: ImageGallery, sidebar: AlbumCheckout },
      props: { main: true, sidebar: true },
    },
    {
      path: "/image/:imageId",
      name: "image",
      components: { main: ImageSingle, sidebar: ImageCheckout },
      props: { main: true, sidebar: true },
    },
  ];

  const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
  });

  Vue.use(VueRouter);
  Vue.use(VueSnotify, {
    toast: {
      showProgressBar: false,
      icon: false,
      position: "rightTop",
      newOnTop: false,
    },
  });
  // Can't run vue in edit mode.
  if (!(window as any).CCM_EDIT_MODE) {
    const app = new Vue({
      el: "#app",
      components: componentList,
      router,
      methods: {
        toggleSearch: (event: Event | undefined) => {
          if (event) {
            event.preventDefault();
          }
          app.showSearch = !app.showSearch;
        },
        toggleOffcanvas: () => (app.showOffcanvas = !app.showOffcanvas),
      },
      data: {
        showSearch: false,
        showOffcanvas: false,
      },
    });

    document.body.classList.remove("vue-loading");

    const searchBtns: NodeListOf<Element> = document.querySelectorAll(
      "[data-toggle-search]"
    );

    searchBtns.forEach((btn) => {
      btn.addEventListener("click", app.toggleSearch);
    });

    const menuBtns: NodeListOf<Element> = document.querySelectorAll(
      "[data-toggle-offcanvas]"
    );

    menuBtns.forEach((btn) => {
      btn.addEventListener("click", app.toggleOffcanvas);
    });

    var selects = document.querySelectorAll("[data-category-picker]");

    selects.forEach((select: any) => {
      console.log("aaa");
      select.addEventListener("change", () => {
        console.log("bbb");
        window.location.replace(select.value);
      });
    });
  }
});
