













import { Vue, Component, Emit } from "vue-property-decorator";

@Component({})
export default class Search extends Vue {
  private searchValue: string = "";

  private handleKeyup(event: KeyboardEvent) {
    if (event.key === "Escape") {
      return this.closeOffcanvas();
    }
  }

  @Emit()
  public closeOffcanvas() {
    return;
  }
}
