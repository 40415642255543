var lazyClass = 'js-lazyload';

export function lazyLoader() {

  //Sets an elements var that checks how many non-visible elements still exist
  // This variable is reset every time lazyLoader() is called
  var elements = document.querySelectorAll('.' + lazyClass + ':not(.js-lazyload--visible)');

  //If there are no hidden elements left, end the function we don't need to proceed.
  if (elements.length === 0) return;

  //Loop through the elements array
  //Only untoggled elements can be in this array because of the previous check
  for (var i = elements.length; i--;) {

    let lazyLoadElement: any = elements[i];

    if (
      lazyLoadElement.getBoundingClientRect().bottom <= window.innerHeight &&
      lazyLoadElement.getBoundingClientRect().bottom >= 0 ||
      lazyLoadElement.getBoundingClientRect().top <= window.innerHeight &&
      lazyLoadElement.getBoundingClientRect().top >= 0) {
      //The element was considered visible, run your stuff!
      lazyLoadElement.classList.add('js-lazyload--visible');

      let imgData = lazyLoadElement.getAttribute('data-image'),
        image: any = new Image(),
        lazyStyle = window.getComputedStyle(lazyLoadElement);

      if (lazyStyle.position !== 'relative') {
        if (lazyStyle.position !== 'absolute') {
          lazyLoadElement.style.position = 'relative';
        }
      }

      image.onload = () => {
        lazyLoadElement.classList.add('js-lazyload--loaded');
        lazyLoadElement.insertBefore(image, lazyLoadElement.firstChild);
      }

      image.classList.add('js-lazyload__image')
      image.style.position = 'absolute';
      image.style.top = 0;
      image.style.left = 0;
      image.style.width = '100%';
      image.style.height = '100%';
      image.style.zIndex = '0';
      image.style.objectFit = 'cover';
      image.src = imgData;


    }
  }
}

lazyLoader();

var scrollWaiting = false;

window.onscroll = function () {
  if (scrollWaiting) {
    return;
  }
  scrollWaiting = true;

  var headerEl = document.querySelector('.topbar');

  let top = pageYOffset;

  top > 200 ? headerEl!.classList.add('topbar--scrolling') : headerEl!.classList.remove('topbar--scrolling');

  lazyLoader();

  setTimeout(function () {
    scrollWaiting = false;
  }, 50);

};