

















import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class HomeVideo extends Vue {
  @Prop() videoId!: string;
  private isPlaying: boolean = false;
  private playerVars = {
    autoplay: 1,
    mute: 1,
    controls: 0,
    modestbranding: 1,
    fs: 0,
    iv_load_policy: 3,
    loop: 1,
    showinfo: 0,
    playlist: this.videoId,
    cc_load_policy: 0,
  };

  private playing() {
    this.isPlaying = true;
  }
}
