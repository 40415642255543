

















import { Vue, Component, Prop } from "vue-property-decorator";
import { lazyLoader } from "../modules/lazyloader";

@Component({})
export default class ImageGallery extends Vue {
  @Prop() private images: { fID: string; src: string }[];

  private mounted() {
    lazyLoader();
  }

  private handleClick(id: string) {
    return this.$router.push(`/image/${id}`);
  }

  private get remainder() {
    return "";
  }
}
