var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "sidebar-layout",
        [
          _c(
            "template",
            { slot: "content" },
            [
              _c("router-view", {
                attrs: { name: "main", album: _vm.album, images: _vm.images }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "sidebar" },
            [
              _c("router-view", {
                attrs: { name: "sidebar", album: _vm.album, images: _vm.images }
              })
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }