






import { Vue, Component, Emit } from "vue-property-decorator";

@Component({})
export default class LoadingIcon extends Vue {}
