var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout-sidebar" }, [
    _c(
      "div",
      {
        class:
          "layout-sidebar__content " +
          (_vm.$route.name === "image" ? "layout-sidebar__content--fixed" : "")
      },
      [_vm._t("content")],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "layout-sidebar__sidebar" },
      [_vm._t("sidebar")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }