













import { Vue, Component, Prop } from "vue-property-decorator";
import axios from "axios";
import { Product } from "../interfaces/store";
import StoreApiRepository from "../api/StoreApi.repository";
import { Container } from "typedi";

@Component({})
export default class AlbumCheckout extends Vue {
  @Prop() private album: any;
  private storeApi: StoreApiRepository = Container.get(StoreApiRepository);
  private product: Product | null = null;

  private created() {
    console.log(this.album);
    if (!this.product) {
      //this.getProduct();
    }
  }

  private async getProduct() {
    try {
      const product = await this.storeApi.getProductById(this.album.productId);

      this.product = product;
    } catch (error) {
      this.$snotify.error(error.message);
    }
  }

  private async addItemToCart() {
    if (!this.product) {
      return this.$snotify.error("Product not configured, please try again.");
    }
  }
}
