var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wysiwyg" }, [
    _c("div", [
      _c("h1", [_vm._v(_vm._s(_vm.album.title))]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { domProps: { innerHTML: _vm._s(_vm.album.wysiwygDesc) } }),
      _vm._v(" "),
      this.product
        ? _c(
            "a",
            {
              staticClass: "cta",
              attrs: { href: "#" },
              on: { click: _vm.addItemToCart }
            },
            [_vm._v("\n      Add To Cart\n    ")]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }