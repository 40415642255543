

















import { Vue, Component, Prop } from "vue-property-decorator";
import { lazyLoader } from "../modules/lazyloader";
import SidebarLayout from "../layouts/sidebar.vue";

@Component({
  components: {
    SidebarLayout,
  },
})
export default class GalleryRoot extends Vue {
  @Prop() private images: { fID: string; src: string }[];
  @Prop() private album: any;
}
