import axios, { AxiosResponse } from 'axios'
import { Service, Container } from 'typedi'
import { Product } from '../interfaces/store'
import PlatformSettings from '../services/PlatformSettings.service'

@Service()
export default class StoreApiRepository {
    protected baseUrl: string
    protected apiExtension: string
    protected apiUrl: string

    constructor() {
        const platformSettings = Container.get(PlatformSettings)
        this.baseUrl = platformSettings.baseUrl
        this.apiExtension = 'store/api'
    }

    public async getProductById(id: number): Promise<Product> {
        try {
            const response: AxiosResponse<Product> = await axios.get(`${this.baseUrl}${this.apiExtension}/product/${id}`)

            return response.data
        } catch (error) {
            throw new Error(error.message)
        }
    }
}