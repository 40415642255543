



































import { Vue, Component, Emit } from "vue-property-decorator";

@Component({})
export default class Search extends Vue {
  private searchValue: string = "";

  private handleKeyup(event: KeyboardEvent) {
    if (event.key === "Escape") {
      return this.closeSearch();
    }
  }

  private mounted() {
    const field = <HTMLInputElement>this.$refs.search;
    field.focus();
  }

  @Emit()
  public closeSearch() {
    return;
  }
}
