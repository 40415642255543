var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home-video" }, [
    _c(
      "div",
      {
        staticClass: "home-video__wrapper",
        class: "" + (_vm.isPlaying ? "home-video__wrapper--is-playing" : "")
      },
      [
        _c("youtube", {
          ref: "youtube",
          staticClass: "home-video__video",
          attrs: { "video-id": _vm.videoId, "player-vars": _vm.playerVars },
          on: { playing: _vm.playing }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }